import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Послуги BoldSolutions
			</title>
			<meta name={"description"} content={"Більше ніж поле - місце для розкриття вашого потенціалу"} />
			<meta property={"og:title"} content={"Наші послуги | Послуги BoldSolutions"} />
			<meta property={"og:description"} content={"Більше ніж поле - місце для розкриття вашого потенціалу"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/1-2.jpg?v=2024-09-23T11:58:37.084Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/5374788.png?v=2024-09-23T11:29:36.139Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/5374788.png?v=2024-09-23T11:29:36.139Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/5374788.png?v=2024-09-23T11:29:36.139Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/5374788.png?v=2024-09-23T11:29:36.139Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/5374788.png?v=2024-09-23T11:29:36.139Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/5374788.png?v=2024-09-23T11:29:36.139Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="120px 0 120px 0" background="linear-gradient(180deg,rgba(37, 0, 176, 0.9) 1%,rgba(5, 49, 27, 0.89) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://rinma-flas.com/img/5.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Headline-2">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Text
				as="h1"
				margin="0px 0px 20px 0px"
				font="normal 700 44px/1.2 --fontFamily-sans"
				color="--light"
				sm-font="normal 700 42px/1.2 --fontFamily-sans"
			>
				Більше ніж поле - місце для розкриття вашого потенціалу
			</Text>
			<Text
				margin="0px 180px 80px 0px"
				font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
				color="#aab1b9"
				lg-margin="0px 0 50px 0px"
				md-margin="0px 0 50px 0px"
			>
				BoldSolutions - це більше, ніж просто клаптик трави, ми надаємо послуги, які покращують ваш футбольний досвід, гарантуючи, що кожен візит буде незабутнім.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="25px 25px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
							Ідеальне поле
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
							Повнорозмірне футбольне поле: Стандартного розміру, гарне, доглянуте поле, готове приймати ваші матчі з якістю, яку ви знайдете на професійних майданчиках.
							<br />
							<br />
							Вечірнє освітлення: Наше сучасне прожекторне освітлення гарантує, що ваша гра може тривати навіть після заходу сонця, пропонуючи яскраву атмосферу для нічної гри.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
							Комфорт і зручність
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
							Зручності на території: Роздягальні для комфортного переодягання, закусочні, де ви зможете попити води, а також велика парковка, щоб почати гру без стресу.
							<br />
							<br />
							Прокат інвентарю: Від м'ячів до конусів і воротарських рукавиць - у нас є все найнеобхідніше, щоб ви могли грати, не маючи при собі повної сумки з екіпіровкою.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
							Бронювати легко
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
							Просте онлайн-бронювання: Наша зручна онлайн-система дозволяє легко забронювати наступну гру.
							<br />
							<br />
							Гнучкий розклад: Вибирайте з широкого спектру доступних слотів, щоб відповідати календарю вашої команди.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
							Ваша гра, ваш шлях
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
							Індивідуальний досвід: Хочете організувати турнір або спеціальний захід? Зв'яжіться з нами, щоб обговорити, як ми можемо адаптувати налаштування поля до ваших потреб.
							<br />
							<br />
							Ексклюзивний доступ: Коли ви бронюєте у нас, поле належить вам і тільки вам - ніяких перерв, ніякого спільного використання, тільки безперервна гра.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Пориньте в атмосферу гри і нехай поля BoldSolutions стануть тлом для вашої футбольної подорожі. Зв'яжіться з нами, щоб дізнатися більше, або, ще краще, приїжджайте і подивіться, що робить наше місце місцевою любов'ю до гри.
						<br />
						<br />
						Поля BoldSolutions - піднесіть свою гру, відсвяткуйте свою пристрасть
					</Text>
					<Button
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						background="--color-primary"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Контакти
					</Button>
				</Box>
				<Image
					src="https://uploads.quarkly.io/66f139c641a0cf0024a41f20/images/2-3.jpg?v=2024-09-23T11:58:37.093Z"
					display="block"
					width="100%"
					md-order="-1"
					srcSet="https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/2-3.jpg?v=2024-09-23T11%3A58%3A37.093Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/2-3.jpg?v=2024-09-23T11%3A58%3A37.093Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/2-3.jpg?v=2024-09-23T11%3A58%3A37.093Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/2-3.jpg?v=2024-09-23T11%3A58%3A37.093Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/2-3.jpg?v=2024-09-23T11%3A58%3A37.093Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/2-3.jpg?v=2024-09-23T11%3A58%3A37.093Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139c641a0cf0024a41f20/images/2-3.jpg?v=2024-09-23T11%3A58%3A37.093Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});